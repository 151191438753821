import { responsiveFontSizes, createMuiTheme } from "@material-ui/core"
import grey from "@material-ui/core/colors/grey"

import BungeeInlineRegularWoff2 from "../fonts/BungeeInline-Regular.woff2"
import BungeeInlineRegularWoff from "../fonts/BungeeInline-Regular.woff"

// NOTE: this statically hosted font version has
// line height fixes using Font Squirrel font tool
const bungeeInline = {
  fontFamily: "Bungee Inline",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `url(${BungeeInlineRegularWoff2}) format('woff2'),
        url(${BungeeInlineRegularWoff}) format('woff')`,
}

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#24c4a2",
      main: "#18816A",
    },
    grey,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [bungeeInline],
      },
    },
  },
  typography: {
    secondary: '"Bungee Inline", sans-serif',
  },
})

const defaultTheme = responsiveFontSizes(theme)

export default defaultTheme
