const getRelatedArticles = (relatedArticleSlugs, articleData) => {
  if (relatedArticleSlugs) {
    return relatedArticleSlugs.map((relatedArticleSlug) => {
      return articleData.find(
        (article) => article.frontmatter.slug === relatedArticleSlug
      )
    })
  }

  return null
}

const getArticlesByCategorySlug = (articles, category) => {
  return articles.filter((article) => article.frontmatter.category === category)
}

const getArticleLink = (slug) => {
  return `/straipsniai/${slug}`
}

export { getArticlesByCategorySlug, getArticleLink, getRelatedArticles }
