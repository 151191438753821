import React from "react"
import PropTypes from "prop-types"
import {
  MuiThemeProvider,
  withTheme as withMaterialUITheme,
} from "@material-ui/core/styles"
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components"

import defaultTheme from "../../src/theme"

const MaterialUITheme = (props) => {
  return (
    <MuiThemeProvider theme={defaultTheme}>{props.children}</MuiThemeProvider>
  )
}

MaterialUITheme.propTypes = {
  children: PropTypes.any,
}

const StyledComponentsTheme = (props) => {
  return (
    <StyledComponentsThemeProvider theme={props.theme}>
      {props.children}
    </StyledComponentsThemeProvider>
  )
}

StyledComponentsTheme.propTypes = {
  children: PropTypes.any,
  theme: PropTypes.object,
}

const StyledComponentsThemeWithMaterialUI = withMaterialUITheme(
  StyledComponentsTheme
)

const Theme = (props) => {
  return (
    <React.Fragment>
      <MaterialUITheme>
        <StyledComponentsThemeWithMaterialUI>
          {props.children}
        </StyledComponentsThemeWithMaterialUI>
      </MaterialUITheme>
    </React.Fragment>
  )
}

Theme.propTypes = {
  children: PropTypes.node,
}

export default Theme
