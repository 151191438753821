import React from "react"
import PropTypes from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"

import ThemeProvider from "../components/theme-provider"
import Hero from "../components/hero"

import Header from "./header"
import Footer from "./footer"

const Layout = (props) => {
  return (
    <>
      <ThemeProvider>
        <CssBaseline />
        <Header />
        <Hero />
        <main>{props.children}</main>
        <Footer />
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
