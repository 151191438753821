import React from "react"
import styled from "styled-components"
import { Container, Typography } from "@material-ui/core"

const Copy = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.secondary};
`

const Footer = () => {
  return (
    <Container maxWidth="md" component="footer">
      <Copy component="p" variant="h6">
        © 2007-{new Date().getFullYear()} <span>Šeško klubas</span>
      </Copy>
    </Container>
  )
}

export default Footer
