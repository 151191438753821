import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import { Card, Container } from "@material-ui/core"
import Image from "gatsby-image"

const InnerContainer = styled(Card).attrs({ elevation: 0 })`
  ${({ theme }) => `
    width: 100%;
    position: relative;
    margin-bottom: ${theme.spacing(4)}px;
    overflow: hidden;
    background-color: #EBEBED;
  `}
`

const StyledImage = styled(Image)`
  width: 100% !important;

  img {
    object-position: center right !important;
  }
`

const TextContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 100%;

  ${({ theme }) => `
    ${theme.breakpoints.up("sm")} {
      top: 8%;
      left: 2%;
      bottom: auto;
      width: 50%;
    }

    ${theme.breakpoints.up("md")} {
      top: 12%;
      left: 2%;
    }
  `}
`

const Text = styled.span`
  ${({ theme }) => `
    display: inline;
    font-family: ${theme.typography.secondary};
    margin-top: 16px;
    padding: 0 8px;
    font-size: 16px;
    line-height: 1.85;
    text-transform: uppercase;
    background-color: ${theme.palette.primary.light};
    box-decoration-break: clone;
    
    ${theme.breakpoints.up("sm")} {
      position: relative;
      font-size: 21px;
      padding: 0 8px;
    }

    ${theme.breakpoints.up("md")} {
      font-size: 24px;
    }
  `}
`

const Hero = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(name: { eq: "hero-image" }) {
            childImageSharp {
              fixed(quality: 90, height: 250) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <Container maxWidth="md">
            <InnerContainer>
              <StyledImage fixed={data.desktop.childImageSharp.fixed} />
              <TextContainer>
                <Text>
                  Mūsų tikslas - suvienyti šeškų augintojus ir dalintis žiniomis
                </Text>
              </TextContainer>
            </InnerContainer>
          </Container>
        )
      }}
    ></StaticQuery>
  )
}

export default Hero
