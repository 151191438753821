import React, { useState } from "react"
import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"
import { Card } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import { InputBase, fade, Typography, Link } from "@material-ui/core"
import algoliasearch from "algoliasearch/lite"
import {
  Configure,
  InstantSearch,
  connectHits,
  connectSearchBox,
  PoweredBy,
} from "react-instantsearch-dom"

import { getArticleLink } from "../utils/article"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)

const SearchBoxContainer = styled(Card).attrs({ elevation: 2 })`
  ${({ theme }) => `
    position: absolute;
    z-index: 1;
    width: 288px;
    right: 0;
    margin-top: ${theme.spacing(1)}px;
    padding: ${theme.spacing(2)}px;

    ${theme.breakpoints.up("md")} {
      width: 324px;
    }
  `}
`

const SearchContainer = styled.div`
  ${({ theme }) => `
    position: relative;
    border-radius: ${theme.shape.borderRadius}px;
    background-color: ${fade(theme.palette.grey[500], 0.15)};
    margin-left: 0;
    width: 100%;

    &:hover {
      backgroundColor: ${fade(theme.palette.grey[500], 0.25)};
    }

    ${theme.breakpoints.up("sm")} {
      margin-top: 4px;
      margin-left: auto;
      width: auto;
    }
  `}
`

const SearchIconContainer = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(0, 2)};
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

const StyledInputBase = styled(InputBase)`
  .MuiInputBase-root {
    color: inherit;
  }

  .MuiInputBase-input {
    ${({ theme }) => `
      padding: ${theme.spacing(1, 1, 1, 0)};
      padding-left: calc(1em + ${theme.spacing(4)}px);
      transition: ${theme.transitions.create("width")};
      width: 100%;

      ${theme.breakpoints.up("sm")} {
        width: 12ch;

        &:focus {
          width: 20ch;
        }
      }
    `}
  }
`

const SearchResultsTitle = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.secondary};
`

const List = styled("ol")`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ListItem = styled(Typography)`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(1)}px;
  `}

  &:last-of-type {
    margin-bottom: 0;
  }
`

const AlgoliaSearchLogo = styled(PoweredBy)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 10px;

  ${({ theme }) => `
    margin-top: ${theme.spacing(2)}px;
  `}

  & > span {
    padding-right: 8px;
    padding-bottom: 4px;
  }
`

const Hits = (props) => {
  const { hits, onClick } = props

  return (
    <SearchBoxContainer>
      <SearchResultsTitle component="p" variant="overline">
        Rezultatai
      </SearchResultsTitle>
      <List>
        {hits.length ? (
          hits.map((hit) => {
            const { objectID, slug, title } = hit
            const link = getArticleLink(slug)

            return (
              <ListItem component="li" variant="subtitle2" key={objectID}>
                <Link component={GatsbyLink} to={link} onClick={onClick}>
                  {title}
                </Link>
              </ListItem>
            )
          })
        ) : (
          <ListItem component="li" variant="subtitle2">
            Nieko nerasta
          </ListItem>
        )}
      </List>
      <AlgoliaSearchLogo />
    </SearchBoxContainer>
  )
}

const CustomHits = connectHits(Hits)

const SearchBox = ({ currentRefinement, refine, onChange }) => {
  return (
    <form onSubmit={(event) => event.preventDefault()} noValidate role="search">
      <SearchIconContainer>
        <SearchIcon />
      </SearchIconContainer>
      <StyledInputBase
        type="search"
        placeholder="Ieškoti…"
        inputProps={{ "aria-label": "ieškoti" }}
        value={currentRefinement}
        onChange={(event) => {
          const input = event.currentTarget.value
          onChange(input)
          refine(input)
        }}
      />
    </form>
  )
}

const CustomSearchBox = connectSearchBox(SearchBox)

const Search = () => {
  const [isResultBoxVisible, setIsResultBoxVisible] = useState(false)

  const handleResultClick = () => {
    return setIsResultBoxVisible(false)
  }

  const handleChange = (input) => {
    if (input.length > 0) {
      return setIsResultBoxVisible(true)
    }

    return setIsResultBoxVisible(false)
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
    >
      <Configure hitsPerPage={5} />
      <SearchContainer>
        <CustomSearchBox onChange={handleChange} />
        {isResultBoxVisible && <CustomHits onClick={handleResultClick} />}
      </SearchContainer>
    </InstantSearch>
  )
}

export default Search
