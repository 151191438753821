import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Container, Toolbar } from "@material-ui/core"

import Search from "../components/search"
import Logo from "../images/logo.svg"

const StyledLogo = styled(Logo)`
  ${({ theme }) => `
    height: 24px;

    ${theme.breakpoints.up("sm")} {
      height: 30px;
    }
  `}
`

const StyledContainer = styled(Container)`
  flexgrow: 1;
`

const Header = () => {
  return (
    <StyledContainer maxWidth="md" component="header">
      <Toolbar disableGutters>
        <Link to="/">
          <StyledLogo />
        </Link>
        <Search />
      </Toolbar>
    </StyledContainer>
  )
}

export default Header
